import { Offer } from '../../types/OfferModel';
import { Subtitle } from '../Subtitle';
import { useCopy } from '../../contexts/CopyContext';

type HeaderTextSubtitle = Pick<Offer, 'offerType'> & { isAsymmetric: boolean };

export const HeaderTextSubtitle = ({
  offerType,
  isAsymmetric,
}: HeaderTextSubtitle) => {
  const copy = useCopy();

  return copy.headerTextSubtitle ? (
    <Subtitle
      centered={false}
      text={copy.headerTextSubtitle}
      offerType={offerType}
      fontSize={isAsymmetric ? '20px' : undefined}
    ></Subtitle>
  ) : null;
};
