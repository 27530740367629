export const fanduelNavy = '#1F375B';

export const fanduelBlue = '#1493FF';
export const fanduelBlueD1 = '#1381E0';

export const casinoPurple = '#61019B';
export const casinoBlue = '#0160C9';

export const fanduelGrey = '#B9C4CB';

export const fanduelGreyD3 = '#818E95';
export const fanduelGreyD4 = '#445058';

export const fanduelGreyL2 = '#CFD6DB';
export const fanduelGreyL3 = '#E4E8EE';
export const fanduelGreyL4 = '#F0F3F8';
export const fanduelGreyL5 = '#F5F8FC';
export const fanduelGreyL6 = '#EAF0F6';

export const fanduelGreen = '#1BB152';
export const fanduelGreenD1 = '#189f4a';
export const fanduelGreenD2 = '#137C39';

export const fanduelGreenL1 = '#32B964';

export const fanduelRedD2 = '#BF2D2D';

export const fanduelWhite = '#FFFFFF';

export const black = '#000000';
