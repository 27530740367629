import styled from 'styled-components';

import { useCopy } from 'Contexts';

export const Text = styled.div`
  font-size: 40px;
  width: 60vw;
  white-space: pre-wrap;
  display: block;
  ${({ theme }) => `
        color: ${theme.colors.headerText};
        ${
          theme.mediaQueries.large.maxWidth &&
          `@media (max-width: ${theme.mediaQueries.large.maxWidth}) {
                width: 75vw;
            }`
        }
        ${
          theme.mediaQueries.medium.maxWidth &&
          `@media (max-width: ${theme.mediaQueries.medium.maxWidth}) {
                width: 85vw;
            }`
        }
        ${
          theme.mediaQueries.medium.maxWidth &&
          `@media (max-width: ${theme.mediaQueries.medium.maxWidth}) {
                width: 90vw;
                font-size: 20px;
            }`
        }
    `}
  text-align: center;
`;

export const Bold = styled.span`
  font-size: 40px;
  font-weight: bold;
  display: inline-block;
  ${({ theme }) => `
        color: ${theme.colors.successTextLight};
        ${
          theme.mediaQueries.medium.maxWidth &&
          `@media (max-width: ${theme.mediaQueries.medium.maxWidth}) {
                font-size: 24px;
            }`
        }
    `}
`;

export const LandingWelcome = () => {
  const copy = useCopy();
  const { landingWelcome, landingWelcomeReward } = copy;

  return (
    <Text data-testid="welcome-text">
      {landingWelcome}
      <Bold>{landingWelcomeReward}</Bold>
    </Text>
  );
};
