import { useTheme } from 'styled-components';
import {
  OfferType,
  RewardType,
} from '../../types/OfferModel';
import { Link } from '../Link';
import { Card, CardHeader, CardTitle, TnCContainer } from './HowItWorksStyles';
import { Step } from './Step';
import {
  casBonusAsymmetricStep,
  casBonusStep,
  casBonusZeroRecruitStep,
  casWagerStep,
  HowItWorksStep,
  joinStep,
  sbkBonusAsymmetricStep,
  sbkProfitBoostTokenStep,
  sbkBonusStep,
  sbkBonusZeroRecruitStep,
  sbkWagerStep,
  casBonusSpinsStep,
  casBonusSpinsZeroRecruitStep,
  RewardSpecificHowItWorksStep,
  sbkProfitBoostTokenZeroRecruitStep,
} from './StepsData';
import { determineRewardSplitType } from '../../../app/utils/commonFunctions';

export type StepProps = {
  label: string;
  description: string;
  icon: string;
  color: string;
  terms?: string;
};

export type HowItWorksProps = {
  sbkReferrerRewardAmount: number;
  sbkRefereeRewardAmount: number;
  sbkWagerAmount: number;
  casinoReferrerRewardAmount: number;
  casinoRefereeRewardAmount: number;
  casinoWagerAmount: number;
  offerType: OfferType;
  tncUrl: string;
  offerWindowDays: number;
  rewardType: RewardType;
  referrerPercentage: number;
};

export const HowItWorks = ({
  sbkReferrerRewardAmount = 0,
  sbkRefereeRewardAmount = 0,
  sbkWagerAmount,
  casinoReferrerRewardAmount = 0,
  casinoRefereeRewardAmount = 0,
  casinoWagerAmount,
  offerType,
  tncUrl,
  offerWindowDays,
  rewardType,
  referrerPercentage,
}: HowItWorksProps) => {
  const hybrid = offerType == 'HYBRID';
  const theme = useTheme();

  const productSpecficSteps = getProductSpecificSteps();

  const arrangeSteps = productSpecficSteps.reduce(
    (array: HowItWorksStep[][], element) => {
      element.product == theme.name.toUpperCase()
        ? array[0].push(element)
        : array[1].push(element);
      return array;
    },
    [[joinStep], []]
  );

  const getSteps = hybrid
    ? ([] as HowItWorksStep[]).concat(arrangeSteps[0], arrangeSteps[1])
    : arrangeSteps[0];

  const replaceWith: {
    [id: string]: number;
  } = {
    '%sbkWagerAmount%': sbkWagerAmount,
    '%sbkReferrerRewardAmount%': sbkReferrerRewardAmount,
    '%sbkRefereeRewardAmount%': sbkRefereeRewardAmount,
    '%casinoWagerAmount%': casinoWagerAmount,
    '%casinoReferrerRewardAmount%': casinoReferrerRewardAmount,
    '%casinoRefereeRewardAmount%': casinoRefereeRewardAmount,
    '%offerWindowDays%': offerWindowDays || 30,
    '%referrerPercentage%': referrerPercentage,
  };

  const getLabel = (label: string) => {
    return label.replace(
      /%sbkWagerAmount%|%sbkReferrerRewardAmount%|%sbkRefereeRewardAmount%|%casinoWagerAmount%|%casinoReferrerRewardAmount%|%casinoRefereeRewardAmount%|%offerWindowDays%|%referrerPercentage%/gi,
      (match) => replaceWith[match]?.toString()
    );
  };

  const getColor = (isBonusColor: boolean) =>
    isBonusColor ? theme.colors.successTextLight : theme.colors.defaultText;

  const convertSteps: StepProps[] = getSteps.map((s: HowItWorksStep) => {
    return {
      label: hybrid ? getLabel(s.label + s.labelExtension) : getLabel(s.label),
      description: getLabel(s.description),
      terms: s.terms,
      icon: s.icon,
      color: getColor(s.isBonusColor),
    };
  });

  const steps: StepProps[] = convertSteps;

  return (
    <Card data-testid="how-it-works">
      <CardHeader>
        <CardTitle>How it works</CardTitle>
      </CardHeader>

      {steps.map((step) => {
        const s = {
          ...step,
        };
        return <Step key={step.label} {...s} />;
      })}

      <TnCContainer>
        <Link
          href={tncUrl}
          children={<>Terms and conditions</>}
          target="_blank"
          fontSize={14}
        />
      </TnCContainer>
    </Card>
  );

  function getProductSpecificSteps(): HowItWorksStep[] {
    const rewardSplitType = determineRewardSplitType(
      sbkReferrerRewardAmount,
      sbkRefereeRewardAmount,
      casinoReferrerRewardAmount,
      casinoRefereeRewardAmount,
      rewardType
    );

    const baseSteps: HowItWorksStep[] = [sbkWagerStep, casWagerStep];

    const typeSpecificSteps: RewardSpecificHowItWorksStep = {
      EXTRA_WALLET_REWARD: {
        symmetric: [sbkBonusStep, casBonusStep],
        asymmetric: [sbkBonusAsymmetricStep, casBonusAsymmetricStep],
        zeroRecruit: [sbkBonusZeroRecruitStep, casBonusZeroRecruitStep],
      },
      BONUS_AWARDING_REWARD: {
        symmetric: [sbkBonusStep, casBonusStep],
        asymmetric: [sbkBonusAsymmetricStep, casBonusAsymmetricStep],
        zeroRecruit: [sbkBonusZeroRecruitStep, casBonusZeroRecruitStep],
      },
      PRICE_BOOST_REWARD: {
        symmetric: [sbkProfitBoostTokenStep],
        zeroRecruit: [sbkProfitBoostTokenZeroRecruitStep],
      },
      BONUS_SPINS_REWARD: {
        symmetric: [casBonusSpinsStep],
        zeroRecruit: [casBonusSpinsZeroRecruitStep],
      },
    };

    return [
      ...baseSteps,
      ...(typeSpecificSteps?.[rewardType]?.[rewardSplitType] || []),
    ];
  }
};
