import { useEffect } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import Cookies from 'js-cookie';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import root from 'window-or-global';
import * as amplitude from '@fanduel/core-analytics';
import { Loading } from '../../lib/components';
import { LandingPage } from '../../lib/views';
import { tagScreenLoad } from '../api/gtmAPI';
import { useGetOfferDetailsQuery } from '../api/rafAPI';
import { ContextWrapper } from '../contexts/AppContext';
import { GtmProps } from '../types/gtmTypes';
import { getGtmSiteVersion } from '../utils/siteVersionPlatform';
import {
  OFFER_EXPIRED_HEADER,
  OFFER_EXPIRED_MESSAGE,
} from '../../lib/constants';
import { LandingErrorPageContainer } from './ErrorPage';
import { getCountry } from '../utils/getCountry';

const LandingContainer = () => {
  const theme = new URLSearchParams(root.location.hash).get('theme');
  const appName = new URLSearchParams(root.location.search).get('appName');

  let { code } = useParams();
  const country = getCountry(NODE_ENV);

  const errorGtmProps: GtmProps = {
    jurisdiction: undefined,
    path: '/land-error',
    fdAccountId: undefined,
    siteVersion: getGtmSiteVersion,
    loginStatus: 'logged_out',
  };

  useEffect(() => {
    amplitude.log('RAF Land Page Viewed');
  }, []);

  if (code) {
    const {
      data: offerDetailsData,
      error: offerDetailsError,
      isLoading: offerDetailsLoading,
    } = useGetOfferDetailsQuery(code);

    if (offerDetailsLoading) {
      return <ContextWrapper theme={theme} node={<Loading />} />;
    }

    if (offerDetailsError) {
      datadogLogs.logger.error('Unable to get landing page offer data', {
        offerDetailsError,
      });
      return (
        <LandingErrorPageContainer theme={theme} gtmProps={errorGtmProps} />
      );
    }

    if (offerDetailsData) {
      setRedirectCookie();
      const redirectCookie = Cookies.get('referrer-redirect');

      const landGtmProps: GtmProps = {
        ...errorGtmProps,
        path: '/land/' + code,
      };

      tagScreenLoad(landGtmProps);

      const joinUrl = buildJoinUrl(code);

      const { referralOfferDTO, referrerUsername, referrerFirstName } = offerDetailsData;

      const { startDate, endDate } = referralOfferDTO;

      const now = moment.utc();
      const isValid = now.isBetween(
        moment.utc(startDate, 'YYYY-MM-DDTHH:mm'),
        moment.utc(endDate, 'YYYY-MM-DDTHH:mm')
      );

      datadogLogs.logger.info('GET response landing page successful', {
        isValid,
        offerDetailsData,
        redirectCookie: redirectCookie,
      });

      if (isValid) {
        const landingPage = (
          <LandingPage
            referralOfferDTO={referralOfferDTO}
            referrerUsername={referrerUsername}
            referrerFirstName={referrerFirstName}
            joinUrl={joinUrl}
            country={country}
          />
        );
        return (
          <ContextWrapper theme={theme} node={landingPage} appName={appName} />
        );
      } else {
        return (
          <LandingErrorPageContainer
            theme={theme}
            gtmProps={errorGtmProps}
            header={OFFER_EXPIRED_HEADER}
            message={OFFER_EXPIRED_MESSAGE}
          />
        );
      }
    }
  }

  return <LandingErrorPageContainer theme={theme} gtmProps={errorGtmProps} />;
};

const setRedirectCookie = () => {
  const cookieDomain =
    NODE_ENV !== 'local'
      ? // Product domain with a leading .
        process.env.HOME_URL.replace(/^https?:\/\//, '.')
      : '';

  Cookies.remove('referrer-redirect');
  Cookies.set('referrer-redirect', process.env.HOME_URL, {
    domain: cookieDomain,
    // One hour from now
    expires: 1 / 24,
  });
};

const buildJoinUrl = (code: string) => {
  const ampSessionId = amplitude.getSessionId();
  const ampDeviceId = amplitude.getDeviceId();
  const baseUrl = process.env.JOIN_URL;

  return `${baseUrl}?raf=${code}&external-referrer=${process.env.HOME_URL}&ampSessionId=${ampSessionId}&ampDeviceId=${ampDeviceId}`;
};

export default LandingContainer;
