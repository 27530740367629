import { useState, useEffect } from 'react';
import useMeasure from 'react-use-measure';
import { ResizeObserver } from '@juggle/resize-observer';
import styled, { useTheme } from 'styled-components';
import { ErrorPage } from './ErrorPage';
import { postHeight } from '../../utils/heightPostMessage';

const Container = styled.div`
  width: 100vw;
  overflow: hidden;
`;

export const ReferralCenterErrorPage = () => {
  const theme = useTheme();

  const [componentHeight, setComponentHeight] = useState(0);
  const [ref, { height }] = useMeasure({ polyfill: ResizeObserver });

  useEffect(() => {
    setComponentHeight(height);
  }, [height]);

  useEffect(() => {
    if (theme.name == 'casino') {
      postHeight(height);
    }
  }, [componentHeight]);

  return (
    <Container ref={ref}>
      <ErrorPage
        headerText={theme.text.errorPage.referralCenter.headerText}
        message={theme.text.errorPage.referralCenter.message}
        buttonText={theme.text.errorPage.referralCenter.buttonText}
        buttonHref={process.env.HOME_URL}
        buttonTarget={'_parent'}
      />
    </Container>
  );
};
