import { ReactSVG } from 'react-svg';
import { RWebShare } from 'react-web-share';
import styled, { useTheme } from 'styled-components';
import * as amplitude from '@fanduel/core-analytics';
import { tagIt } from '../../utils/gtmService';
import shareIcon from '../../assets/icons/common/Share.svg';
import { isAndroid } from 'react-device-detect';

const Outer = styled.div`
  ${({ theme }) => `
        border: solid 1px ${theme.colors.containerBorder};
        background-color: ${theme.colors.contentBg};
    `}
  display: flex;
  border-radius: 4px;
  height: 44px;
  justify-content: center;
  align-items: center;
`;

const ShareDiv = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.linkText};
  justify-content: center;
  width: 150px;
`;

const ShareText = styled.div`
  padding: 2px 0 0 10px;
`;

type NativeInviteProps = {
  previewText: string;
  link: string;
};
interface AndroidWindow extends Window {
  Android: {
    nativeShare: Function;
  };
}

declare let window: AndroidWindow;

export const NativeInvite = ({ previewText, link }: NativeInviteProps) => {
  const theme = useTheme();
  const shareText = 'Share invite';
  const previewTitle = 'Join me on Fanduel';
  const shareData = {
    text: previewText,
    title: previewTitle,
    url: link,
  };

  const isAndroidNativeShareAvailable = () =>
    theme.name == 'casino' && 'Android' in window;

  const handleAndroid = () => {
    window.Android.nativeShare(shareData.title, shareData.text, shareData.url);
    tagIt('referral_share_link_clicked', shareText, 'referral_share_links');
    amplitude.log('RAF Share Link Copied', { Module: 'raf_hub' });
  };

  const shareButton = (
    <ShareDiv>
      <ReactSVG src={shareIcon} />
      <ShareText>
        <span>{shareText}</span>
      </ShareText>
    </ShareDiv>
  );

  const shareAndroid = <Outer onClick={handleAndroid}>{shareButton}</Outer>;

  const share = () => {
    let cleanedShareData = shareData;

    // Dirty fix to avoid Android crashing when sharing PBT invite
    if (isAndroid) {
      cleanedShareData.text = cleanedShareData.text.replace('%', ' percent');
    }

    return (
      <RWebShare
        data={cleanedShareData}
        sites={['facebook', 'twitter', 'whatsapp', 'mail']}
        onClick={() => {
          tagIt(
            'referral_share_link_clicked',
            shareText,
            'referral_share_links'
          );
          amplitude.log('RAF Share Link Copied', {
            Module: 'raf_hub',
          });
        }}
      >
        <Outer>{shareButton}</Outer>
      </RWebShare>
    );
  };

  return isAndroidNativeShareAvailable() ? shareAndroid : share();
};
