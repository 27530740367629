import { AppName } from '../types';
import { ThemeProvider } from 'styled-components';
import { getTheme } from './themeService';
import { GlobalStyle } from '../themes/GlobalStyle';
import { AppContextProvider } from '../contexts/AppContext';
import React from 'react';

export const withDecorator = (Component: JSX.Element, appName: AppName) => {
  return (
    <AppContextProvider appName={appName}>
      <ThemeProvider theme={getTheme(appName)}>
        <GlobalStyle />
        {Component}
      </ThemeProvider>
    </AppContextProvider>
  );
};
